import React from 'react';
import { Fade, Reveal } from 'react-reveal';
import NoTheaters from '../images/theaters-blocked.svg';
import MpCard from '../images/mp-card.png';
import MPVideo from '../videos/mp.mp4';
import MPloading from '../images/mp-loading.gif';

const MoviepassContent = () => { 
  return (
  <div id={'main-content'}>           
    <section className={'project-detail-hero'} >
      <h1 className={'project-name two-piece-combo'} >
        <span className={'slide-top a1'}>Moviepass </span> 
        <span className={'slide-top a2'}>Product </span>  
        <span className={'slide-top a3'}>Launch</span>
      </h1>
    <Fade >
      <div className={'contribution two-piece-combo'} >
        <div className={'icon'} >&#xe900;</div>
        <ul>
          <li>Product Conception</li>
          <li>Product Design</li>
          <li>Front-End Development</li>
          <li>Product Management</li>
        </ul>        
      </div>
    </Fade>
    </section>

    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          Mission: Impossible
        </h2>
        <Fade fraction={.71}>
        <img 
          alt="Theater chains that blocked Moviepass" 
          className={'noTheaters'} 
          src={NoTheaters} 
        />
        </Fade>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          Moviepass was a subscription membership to watch unlimited movies in the theaters, a Netflix-like experience for the box office. My mission as I chose to accept it, was to be the founding one-man product team.
        </p><p>
          On day one, major theater chains across the country decided to block our access to tickets. Within an instant, the first iteration of the Moviepass app, with direct integration to reserved tickets, was unusable. To stay in business, we needed a new product that was independent of theaters. I had 90 days to launch a new product.
        </p>

      </div>
    </section>
    </Fade>

    <Fade>
    <section className={'project-info inbed'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          Inception
        </h2>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          The idea came to me at the gift card section of the local grocery store: reloadable prepaid cards. If we could not acquire tickets directly from theaters, the next best thing was to give members the exclusive feeling of having their tickets covered. A debit card working through one of the 4 major credit card networks would also allow us to operate independently of theaters.
        </p><p className={'mp-process'}>
          Here’s the process, simplified:
        </p>
      </div>
    </section>
    </Fade>

    <Fade>
    <section className={'project-info inbed mp-process'} >
      <div className={'one-piece'}>
        <ul className={'mp-flow'} >
          <li>
            <span>User Experience</span>
            <span>Business Logic</span>
          </li>
          <li>
            <span>Go to theater</span>
            <span className={'bus-logic'}>Check phone’s GPS location against our database of theater addresses</span>
          </li>
          <li className={'icon'}>&#xe904;</li>
          <li>
            <span>Select a movie and showtime in the app</span>
            <span className={'bus-logic'}>If proximity is confirmed, load funds on the member’s card to match ticket price
            </span>
          </li>
          <li className={'icon'}>&#xe904;</li>
          <li>
            <span>Buy a ticket with your Moviepass card</span>
            <span className={'bus-logic'}>Lock card to block fraudulent use</span>
          </li>
        </ul>
      </div>
    </section>
    </Fade>
    
    <section className={'project-info no-b'} >
      <div className={'project-copy two-piece-combo mobile-hide'} >
        
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          Members would check in to the theater via GPS on their smartphone, then we would load and unlock the card for use. The product was a first of its kind so we had to break ground within the debit card industry as well as theaters. Quick research showed there were not only payment companies that provided branded cards, but also had APIs to control the funds on those cards.
        </p><p>
          I got to work by designing the interface, gathering data, and assembling a small team of developers to put it all together.
        </p>
      </div>
    </section>
    
    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          End Game
        </h2>
        <div className={'MP-card-phone'}>
          <Reveal 
            effect="mp-card-in" 
            effectOut="mp-card-out"
            fraction={.31}
          >
          <img 
            alt="Moviepass card" 
            className={'mp-card'} 
            src={MpCard} 
          />
          </Reveal>
          <Fade duration={10} fraction={.31}>
            <div className={'phone-carousel'}>
              <video 
                poster={MPloading}
                muted 
                preload 
                autoPlay 
                loop 
                playsInline
              >
                <source src={MPVideo} type="video/mp4" />
              </video>
            </div>
        
          </Fade>
        </div>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          Within 90 days of the start of the crisis, we launched the app &amp; card integration. The solution was a success &amp; within days we put members in theater seats! Moviepass subscribers were now able to do everything from view showtimes to grab their tickets at every theater in the US.
        </p>
      </div>
    </section>
    </Fade>
  </div>

  )
}

export default MoviepassContent;
import React, { Component } from "react";
import IndexNav from "../components/indexNav";

class ProjectNavigation extends Component{
  render() {
    return(
      <div id={'project-navigation'}>
        <a onClick={this.props.closeWindow} id={'close-nav'}>&nbsp;</a>
        <IndexNav />
      </div>
    )
  }
}

export default ProjectNavigation;

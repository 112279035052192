import React, { Component } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

class ProjectNav extends Component{
  render() {
    return (
      <div className={'full-width'}>
        <div className={'project-nav'}>
          <AniLink fade duration={.2} color="#fff" to="/" id="back-link">
            <span className={'icon'}>&#xe902;</span>
          </AniLink>
          <a onClick={this.props.onClick} className={'icon'} id={'nav-toggle'}>&#xe901;</a>
        </div>
      </div>
    )
  }
}

export default ProjectNav;
